import i18next from 'i18next';
import { deTranslations } from "./locales/de";
import { itTranslations } from "./locales/it";
import { rgTranslations } from "./locales/rg";
import Swal from 'sweetalert2'


const setTranslations = () => {
  document.querySelectorAll('[data-translation]').forEach(translation => {
    if (translation instanceof HTMLElement) {
      translation.innerHTML = i18next.t(translation.getAttribute('data-translation'));
    }
  })
}

i18next.init({
  lng: 'de',
  resources: {
    de: deTranslations,
    it: itTranslations,
    rg: rgTranslations
  }
}, (err, t) => {
  if (err) return console.log('something went wrong loading', err);
  setTranslations();
});

const urls = [
  ['Cielo', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/cielo.JPG'],
  ['Koala', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/koala.JPG'],
  ['Wäschgi', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/waeschgi.JPG'],
  ['Möbius', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/moebius.JPG'],
  ['Taruga', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/taruga.JPG'],
  ['Dextro', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/dextro.JPG'],
  ['Tweety', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/tweety.JPG'],
  ['Venus', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/venus.jpg'],
  ['Pyros', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/pyros.JPG'],
  ['Darwin', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/darwin.JPG'],
  ['Karibu', 'https://www.battasendas.ch/files/Bilder/KaLei%20Team/karibu.JPG'],
  ['BiPi', 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Baden-Powell_USZ62-96893_%28retouched_and_cropped%29.png'],
];


class Memory {
  private opened = [];
  private match = 0;
  private moves = 0;
  private $deck = document.querySelector<HTMLElement>('.deck');
  private $scorePanel = document.getElementById('score-panel');
  private $moveNum = this.$scorePanel.querySelector<HTMLElement>('.moves');
  private $ratingStars = this.$scorePanel.querySelectorAll<HTMLElement>('i');
  private $restart = this.$scorePanel.querySelector<HTMLElement>('.restart');
  private effectLength = 400;
  private delayJS = 800;
  private delay = this.effectLength + this.delayJS;
  private shuffeledCards = [];

  private gameCardsQTY = this.symbols.length;
  private rank3stars = this.gameCardsQTY + 2;
  private rank2stars = this.gameCardsQTY + 6;
  private rank1stars = this.gameCardsQTY + 10;

  constructor(private symbols: Array<Array<string>>) {
    this.$restart.addEventListener('click', () => {
      Swal.fire({
        title: i18next.t('game.restart.title'),
        text: i18next.t('game.restart.message'),
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: i18next.t('game.restart.cancelButtonText'),
        confirmButtonText: i18next.t('game.restart.confirmButtonText'),
      }).then((isConfirm) => {
        if (isConfirm) {
          this.initGame();
        }
      });
    });
  }

  private preloadImage(src) {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = resolve
      image.onerror = reject
      image.src = src
    })
  }

  // Source: http://stackoverflow.com/a/2450976
  private shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  public initGame() {
    this.shuffeledCards = this.shuffle([...this.symbols, ...this.symbols]);
    this.$deck.innerHTML = '';
    this.match = 0;
    this.moves = 0;
    this.setMoveNum();
    this.$ratingStars.forEach(ratingStar => ratingStar.classList.remove('off'));

    Promise.all(this.symbols.map(([_name, url]) => this.preloadImage(url))).then(() => {
      this.$deck.append(...this.shuffeledCards.map((card, index) => this.createEmtpyCard(index)));

      document.querySelectorAll<HTMLElement>('.card').forEach(item => item.addEventListener('click', event => {
        //handle click
        if (document.querySelectorAll('.show').length > 1) {
          return true;
        }

        if (event.currentTarget instanceof HTMLElement) {
          const $this = event.currentTarget
          const cardIndex = parseInt($this.dataset.cardIndex, 10);

          if ($this.classList.contains('open') || $this.classList.contains('match')) {
            return;
          }

          this.showCard(cardIndex);
          $this.classList.add('open', 'show');
          this.opened.push($this.innerHTML);

          // Compare with opened card
          if (this.opened.length > 1) {
            if ($this.innerHTML === this.opened[0]) {
              this.animateMatch();
              this.match++;

              // End Game if match all cards
              if (this.gameCardsQTY === this.match) {
                this.setRating(this.moves);
                setTimeout(() => {
                  this.endGame(this.moves);
                }, 500);
              }
            } else {
              this.animateMissmatch();
            }
            this.opened = [];
            this.moves++;
            this.setRating(this.moves);
            this.setMoveNum();
          }
        }
      }));
    })
  }

  private animateMissmatch() {
    const $openedCards = this.$deck.querySelectorAll<HTMLElement>('.open');

    setTimeout(() => {
      $openedCards.forEach(item => item.classList.add('notmatch', 'animate__animated', 'animate__infinite', 'animate__wobble'));
    }, this.effectLength);
    setTimeout(() => {
      $openedCards.forEach(item => item.classList.remove('animate__animated', 'infinite', 'animate__wobble'));
    }, this.delay / 1.5);
    setTimeout(() => {
      $openedCards.forEach(item => {
        item.classList.remove('open', 'show', 'notmatch', 'animate__animated', 'animate__infinite', 'animate__wobble');
        this.hideCard(item);
      });
    }, this.delay);
  }

  private animateMatch() {
    setTimeout(() => {
      this.$deck.querySelectorAll('.open').forEach(item => item.classList.add('match', 'animate__animated', 'animate__infinite', 'animate__rubberBand'));
    }, this.effectLength);
    setTimeout(() => {
      this.$deck.querySelectorAll('.match').forEach(item => item.classList.remove('open', 'show', 'animate__animated', 'animate__infinite', 'animate__rubberBand'));
    }, this.delay);
  }

  private setMoveNum() {
    this.$moveNum.innerText = this.moves.toString();
  }

  private setRating(moves) {
    var rating = 3;
    if (moves > this.rank3stars && moves < this.rank2stars) {
      this.$ratingStars[2].classList.add('off');
      rating = 2;
    } else if (moves > this.rank2stars && moves < this.rank1stars) {
      this.$ratingStars[1].classList.add('off');
      rating = 1;
    } else if (moves > this.rank1stars) {
      this.$ratingStars[0].classList.add('off');
      rating = 0;
    }
    return {score: rating};
  }

  private createEmtpyCard(index: number) {
    const cardEl = document.createElement('li');
    cardEl.setAttribute('data-card-index', index.toString());
    cardEl.classList.add('card');
    const front = document.createElement('div');
    front.classList.add('front');
    const back = document.createElement('div');
    const label = document.createElement('span');
    front.append(label);
    back.classList.add('back');
    // set background
    cardEl.append(front, back);
    return cardEl;
  }

  private showCard(cardIndex: number) {
    const cardEl = this.$deck.querySelector<HTMLElement>(`[data-card-index="${cardIndex}"]`);
    const front = cardEl.querySelector<HTMLElement>('.front');
    front.style.backgroundImage = `url("${this.shuffeledCards[cardIndex][1]}")`;
    front.querySelector<HTMLSpanElement>('span').innerText = this.shuffeledCards[cardIndex][0];
  }

  private hideCard(item: HTMLElement) {
    const front = item.querySelector<HTMLElement>('.front');
    front.style.backgroundImage = undefined;
    front.querySelector<HTMLSpanElement>('span').innerText = '';
  }

  private endGame(moves) {
    Swal.fire({
      title: i18next.t('game.congratulations.title'),
      text: i18next.t('game.congratulations.message', { moves}),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: i18next.t('game.congratulations.confirmButtonText'),
      cancelButtonText: i18next.t('game.congratulations.cancelButtonText'),
    }).then(confirm => {
      if(confirm) {
        this.initGame();
      }
    });
  }
}

// const memory = new Memory(urls);
const memory = new Memory(urls);
memory.initGame();

document.getElementById('game-link').addEventListener('click', (event => {
  event.preventDefault();
  const link = event.currentTarget;
  if (link instanceof HTMLAnchorElement) {
    document.querySelector(link.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  }
}));

document.querySelectorAll('.language-selection a').forEach(link => link.addEventListener('click', event => {
  event.preventDefault();
  const link = event.currentTarget;
  if (link instanceof HTMLAnchorElement) {
    i18next.changeLanguage(link.getAttribute('anchor')).then((t) => setTranslations());
  }
}));
