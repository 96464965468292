import { ResourceLanguage } from "i18next";

export const rgTranslations: ResourceLanguage = {
  translation: {
    intro: {
      title: 'Advent, Advent',
      description: 'Che an da battasendas! Finelmaing darcho ün Pfi-La chantunel – zieva 3 ans, finelmaing ün champ federel – zieva 14 ans! Quista 8evla fnestra d’Advent vögli dedicher a la squedra dals mneders chantunels e las mnedras chantunelas chi s’ingaschan sainza fin pels battasendas in nos chantun.<br> ün grandischem M-E-R-C-I!!',
      memory: 'Cun quist memori als poust imprender a cugnuoscher :-) Bun divertimaint ed ün bel temp d’Advent. Ps: qualchün es entro adascus... ;-)',
      salutations: 'Chers salüds'
    },
    game: {
      moves: 'trattas',
      restart: {
        message: 'Est sgür*a??',
        title: 'Cumainza da nouv',
        confirmButtonText: 'Cumainza da nouv',
        cancelButtonText: 'smetter',
      },
      congratulations: {
        title: 'Bravo',
        message: 'Tü hest glivro il memori in {{moves}} trattas! Eau at giavüsch ün bel temp d’Advent e già uossa bellas Festas da Nadel!',
        confirmButtonText: 'Cumainza da nouv',
        cancelButtonText: 'smetter',
      }
    }
  }
}
