import { ResourceLanguage } from "i18next";

export const itTranslations: ResourceLanguage = {
  translation: {
    intro: {
      title: 'Avvento, avvento',
      description: 'Che anno per gli esploratori: Un altro campo cantonale di Pentecoste dopo 2 anni - e un campo nazionale dopo 14 anni di pausa! Vorrei dedicare questa ottava finestra di Avvento alla nostra guida cantonale che lavora instancabilmente per il nostro Cantone.',
      memory: 'In questo gioco Memory potrete conoscerli tutti :-) Buon divertimento e un meraviglioso periodo di Avvento! P.S. Qualcuno si è intrufolato... ;-)',
      salutations: 'Cari saluti'
    },
    game: {
      moves: 'Prove',
      restart: {
        message: 'Sei sicuro?',
        title: 'Iniziare un nuovo',
        confirmButtonText: 'Iniziare un nuovo',
        cancelButtonText: 'Cancellare',
      },
      congratulations: {
        title: 'Congratulazioni',
        message: 'Hai risolto il Memory in {{movimenti}} provi! Vi auguro un meraviglioso periodo di Avvento e un felice Natale!',
        confirmButtonText: 'Iniziare un nuovo',
        cancelButtonText: 'Cancellare',
      }
    }
  }
}
