import { ResourceLanguage } from "i18next";

export const deTranslations: ResourceLanguage = {
  translation: {
    intro: {
      title: 'Advent, Advent',
      description: 'Was für ein Pfadijahr: Wieder ein kantonales Pfingstlager nach 2 Jahren - und ein Bundeslager nach 14 Jahren Pause! Ich möchte dieses 8. Adventsfenster unserer kantonalen Leitung widmen, welche sich unermüdlich für unseren Kanton einsetzen.<br>ein riesiges M-E-R-C-I!',
      memory: 'Im folgenden Memory kannst du sie alle kennenlernen :-). Viel Spass und eine schöne Adventszeit! P.S. Jemand hat sich eingeschlichen... ;-)',
      salutations: 'Liebe Grüsse'
    },
    game: {
      moves: 'Versuche',
      restart: {
        message: 'Bist du sicher?',
        title: 'Neu starten',
        confirmButtonText: 'Neu starten',
        cancelButtonText: 'Abbrechen',
      },
      congratulations: {
        title: 'Glückwunsch',
        message: 'Du hast das Memory in {{moves}} Versuchen gelöst! Ich wünsche dir eine schöne Adventszeit und bereits jetzt ganz frohe Weihnachten!',
        confirmButtonText: 'Neu starten',
        cancelButtonText: 'Abbrechen',
      }
    }
  }
}
